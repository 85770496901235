* {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	list-style: none;
	outline: none;
	box-sizing: border-box;
}

:root {
	--white: #fff;
	--text: #F2F2F2;
	--bg: #12121a;
	--bg-darkpurple: #1c0913;
	--bg-darkgreen: #002625;
	--bg-input: #4B4B4B;
	--red: #ba0035;
	--greenwater: #00beb7;
	--gradient: linear-gradient(80deg, #ba0035 18%, #5b5b84 70%, #00beb7 105%);
}

::-webkit-scrollbar {
	width: .7rem;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	border-radius: 1rem;
}
html {
	scrollbar-width: thin;
	scroll-behavior: smooth;
	background-color: var(--bg);
}

body,
button,
input {
	font-family: 'Sulphur Point', sans-serif;
	font-size: 1.15rem;
	font-weight: 300;
	font-display: swap;
	line-height: 1.5;
	color: var(--text);
}
input[type="checkbox"] {
	width: min-content;
}
h1,
h2,
h3 {
	margin-bottom: 1.5rem;
	color: var(--white);
}
button {
	padding: .3rem 1.5rem;
	border-radius: 3rem;
	font-weight: 700;
	border: double .2rem transparent;
	background: var(--gradient);
	background-clip: padding-box, border-box;
	background-origin: border-box;
	transition: .6s ease-in;
}
button[type=submit] {
	padding: .8rem;
	aspect-ratio: 1;
}
button[type=submit] svg {
	width: 1.5rem;
	aspect-ratio: 1;
}
button:hover,
button:active {
	background-image: linear-gradient( var(--bg-input), var(--bg-input) ), var(--gradient);
}
button.edit,
button.delete {
	padding: 0 .6rem;
	background: transparent;
}
button.edit::before {
	content: '\22EE';
}
button.delete::before {
	content: '\002715';
}
button.gotop {
	display: none;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	aspect-ratio: 1;
	background: transparent;
	opacity: .6;
	transition: .6s ease-in;
	z-index: 9995;
}
button.gotop::before,
button.gotop::after {
	content: '';
	display: block;
	position: absolute;
	right: 0;
	bottom: 1rem;
	width: 40%;
	height: .12rem;
	background: var(--white);
	transform: rotate(45deg);
}
button.gotop::after {
	right: .8rem;
	transform: rotate(-45deg);
}
button.gotop:hover {
	opacity: 1;
}
.visible {
	display: block !important;
}
.nav-toggle {
	display: none;
}
button.nav-toggle:hover,
button.nav-toggle:active {
	background-image: none;
}
nav {
	display: grid;
	justify-content: start;
	align-items: center;
	grid-template-columns: repeat(3, max-content);
	gap: 1rem;
	margin-bottom: 1rem;
}
nav > * {
	margin: 0;
}
nav#navbar {
	gap: 0;
}
nav#navbar ul {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(6, max-content);
	gap: 2rem;
}
nav#navbar ul a {
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--text);
	text-decoration: none;
	transition: .3s ease-in-out;
}
nav#navbar ul a:hover {
	color: var(--bg-input);
}
nav#navbar ul a.active {
	color: var(--red);
	background: var(--gradient);
	background-size: cover;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

input,
select {
	width: 100%;
	padding: .4rem 1rem;
	border-radius: 3rem;
	background-color: var(--bg-input);
	border: .1rem solid var(--bg-input);
	transition: .6s ease-in-out;
}
select {
	width: auto;
	padding: .2rem .5rem;
	color: var(--text);
	background-color: var(--bg-darkgreen);
}
input:focus,
select:focus {
	border: .1rem solid var(--text);
}

#root {
	min-height: 100vh;
	max-width: 100rem;
	margin-inline: auto;
	padding: 2rem 1rem;
}
main {
	padding-top: 3rem;
}
header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 2rem;
	padding-bottom: 1.5rem;
}
.user {
	max-width: 20rem;
	display: grid;
	grid-template-columns: min-content 1fr;
	column-gap: 1rem;
}
.user img {
	width: 3.5rem;
	aspect-ratio: 1;
	border-radius: 100%;
	grid-row: span 2;
	text-align: center;
	background-color: var(--bg-darkgreen);
}

section {
	padding-block: 2rem 4rem;
}

form,
ul.expense-list li {
	display: grid;
	grid-template-columns: 1fr .5fr .5fr 10rem;
	justify-items: start;
	align-items: center;
	gap: 1.5rem;
}
form {
	grid-template-columns: 1fr .5fr .5fr max-content;
}
form div {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: .3rem;
}
ul.expense-list.nobtn li {
	grid-template-columns: 1fr .5fr .5fr;
}
ul.expense-list.nobtn li {
	grid-template-columns: 1fr .5fr .5fr;
}
ul.expense-list.list-summary li {
	grid-template-columns: 1fr 1fr;
}

ul.expense-list {
	margin-top: 1rem;
}
ul.expense-list li {
	padding: .5rem;
	border-bottom: .1rem solid var(--bg-input);
}
ul.expense-list li.tot-expense {
	background-color: var(--bg-darkpurple);
}
ul.expense-list li.tot-earn {
	background-color: var(--bg-darkgreen);
}
ul.expense-list li.no-expense {
	opacity: .6;
}
ul.expense-list div.commands {
	display: flex;
}
ul.expense-list div.commands button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: .3rem;
}

.summary-grid {
	display: grid;
	grid-template-columns: .65fr 1fr;
	gap: 2rem;
}
.summary-grid > ul > li {
	padding: 1rem;
	margin-bottom: 2rem;
	border-radius: .5rem;
	background: var(--bg-input);
}
.summary-filter label {
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	gap: 1.5rem;
	padding-inline: .5rem;
}
.summary-filter select {
	padding-inline: .5rem;
	margin-bottom: 2rem;
}

.txt-center {
	text-align: center;
}

footer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: .6rem;
	padding-block: 4rem 1rem;
}
footer img {
	width: 6rem;
	margin-top: .15rem;
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
}

@media (max-width: 44em) {
	.user > *:not(img) {
		display: none;
	}
	button.nav-toggle {
		display: block;
		position: absolute;
		right: 1rem;
		width: 2.5rem;
		border-radius: 0;
		padding: 0;
		background: transparent;
		z-index: 9999;
	}
	button.nav-toggle .bar1,
	button.nav-toggle .bar2,
	button.nav-toggle .bar3 {
		width: 100%;
		height: .12rem;
		margin-block: .65rem;
		background-color: var(--white);
		transition: .3s ease-in;
	}
	button.nav-toggle[aria-expanded="true"] .bar1 {
		transform: rotate(-45deg) translate(-50%, -50%);
	}
	button.nav-toggle[aria-expanded="true"] .bar2 {
		opacity: 0;
	}
	button.nav-toggle[aria-expanded="true"] .bar3 {
		transform: rotate(45deg) translate(-50%, -50%);
	}
	nav#navbar {
		position: fixed;
		top: 6.8rem; 
		right: 1rem;
		padding: 2rem 3rem;
		background: var(--bg);
		border-radius: 1rem;
		border: .1rem solid var(--bg-input);
		box-shadow: .3rem .5rem .5rem rgba(255,255,255,.15);
		z-index: 99;
		transform: translateX(200%);
		transition: transform .5s ease-in-out;
	}
	nav#navbar ul {
		grid-template-columns: 1fr;
		gap: 1.5rem;
	}
	nav#navbar[data-visible="true"] {
		transform: translateX(0);
	}  

	nav {
		grid-template-columns: repeat(2, max-content);
	}
	nav > h2 {
		grid-column: span 2;
	}
	form {
		grid-template-columns: repeat(2, 1fr);
		place-items: center;
		gap: 1rem;
	}
	form div {
		gap: 1.5rem;
	}
	form input:first-child,
	form div {
		grid-column: span 2;
	}
	ul.expense-list li {
		grid-template-columns: 1fr max-content;
		align-items: center;
		gap: 0 1rem;
		padding-right: 0;
	}
	ul.expense-list div.commands {
		justify-self: end;
	}
	ul.expense-list div.commands span {
		display: none;
	}
	button.delete {
		padding: 0;
	}
	.summary-grid {
		grid-template-columns: 1fr;
		gap: 4rem;
	}
}